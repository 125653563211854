/* PatientFileUploaderModal.css */

.patient-file-uploader-modal.dark-modal .ant-modal-content {
    background-color: #1f1f1f;
    color: #ffffff;
  }
  
  .patient-file-uploader-modal.light-modal .ant-modal-content {
    background-color: #ffffff;
    color: #000000;
  }
  
  .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .custom-dragger .ant-upload-drag-icon {
    color: #1890ff;
  }
  
  .custom-dragger .ant-upload-text {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
  }
  
  .custom-dragger .ant-upload-hint {
    color: rgba(0, 0, 0, 0.45);
  }
  
  .upload-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .upload-buttons .ant-btn {
    min-width: 100px;
  }
  