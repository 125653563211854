/* src/components/PaymentModal.css */

.payment-modal {
  /* Optional: Customize modal styles */
}

.payment-card {
  padding: 20px;
  text-align: center;
  background: linear-gradient(135deg, #f0f2f5 0%, #d9d9d9 100%);
  border-radius: 10px;
}

.payment-card .ant-card-body {
  padding: 0;
}

.payment-card .ant-btn-primary {
  background: #1890ff;
  border-color: #1890ff;
  transition: background 0.3s, border-color 0.3s;
}

.payment-card .ant-btn-primary:hover {
  background: #40a9ff;
  border-color: #40a9ff;
}
