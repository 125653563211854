@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
* {
  font-family: "Raleway", sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-btn-primary {
  color: #fff;
  background: #0070f2;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-btn-primary:hover {
  color: #fff;
  background: #0040B0 !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-menu-item-selected, .ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #0070f2;
}

/* Desktop view */
@media (min-width: 1025px) {
    .mobile-view {
      display: none !important;
    }
    .desktop-view {
      display: block !important;
    }
    .desktop-view-inline {
      display: inline !important;
    }
    .chat-message.assistant, .chat-message.user {
      width: 60%;
    }
    .mobile-sidebar-width{
      margin-left: 80px !important;
    }
    .mobile-layout-margin {
      margin: 24px 30px 0;
    }
}

/* Tablet view */
@media (min-width: 601px) and (max-width: 1024px) {
    .mobile-view {
      display: none !important;
    }
    .desktop-view {
      display: block !important;
    }
    .desktop-view-inline {
      display: inline !important;
    }
    .chat-message.assistant, .chat-message.user {
      width: 60%;
    }
    .mobile-sidebar-width{
      margin-left: 80px !important;
    }
    .mobile-layout-margin {
      margin: 24px 30px 0;
    }
}

/* Mobile view */
@media (max-width: 600px) {
    assistant.ant-list-item-meta-description{
      font-size: 12px !important;
      word-break: break-all; /* Ensures long words break to fit the container */
      white-space: normal; /* Allows the text to wrap to the next line */
    }
    .mobile-view {
      display: block !important;
    }
    .desktop-view {
      display: none !important;
    }
    .desktop-view-inline {
      display: none !important;
    }
    .chat-message.assistant, .chat-message.user {
      width: 90%;
    }
    .mobile-sidebar-width{
      margin-left: 80px !important;
    }
    .mobile-layout-margin {
      margin: 24px 10px 0;
    }
}

/* For Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width for a slim scrollbar */
  height: 6px; /* Adjust the height for a slim scrollbar in horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Handle color */
  border-radius: 10px; /* Optional: Rounded corners for the handle */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Handle color on hover */
}