#waveform-stop-button{
    background-color: #ed0000 !important;
}

#waveform-play-button{
    background-color: #f9ae16 !important;
}

#waveform-pause-button{
    background-color: #00db87 !important;
}

.ant-slider.ant-slider-disabled .ant-slider-track {
    background-color: #0070f2 !important;
}