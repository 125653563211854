/* src/components/LayoutWithHeader.css */

/* Root container transitions */
.layout-container {
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Light Theme Styles */
.layout-container.light {
    background-color: #ffffff;
    color: #000000;
}

/* Dark Theme Styles */
.layout-container.dark {
    background-color: #1f1f1f;
    color: #ffffff;
}

/* Ant Design Card component customization */
.layout-container.light .ant-card {
    background-color: #ffffff;
    color: #000000;
    border-color: #f0f0f0;
}

.layout-container.dark .ant-card {
    background-color: #333333;
    color: #e0e0e0; /* Off-white text for better readability */
    border-color: #444444;
}

/* Ant Design Collapse Panel customization */
.layout-container.light .ant-collapse-panel {
    background-color: #ffffff;
    color: #000000;
}

.layout-container.dark .ant-collapse-panel {
    background-color: #333333;
    color: #e0e0e0;
}

/* Ant Design Tabs customization */
.layout-container.light .ant-tabs-tabpane {
    background-color: #ffffff;
    color: #000000;
}

.layout-container.dark .ant-tabs-tabpane {
    background-color: #333333;
    color: #e0e0e0;
}

/* Styling text elements inside Card, Panel, and Tab components */
.layout-container.dark .ant-card p,
.layout-container.dark .ant-card h1,
.layout-container.dark .ant-card h2,
.layout-container.dark .ant-card h3,
.layout-container.dark .ant-card h4,
.layout-container.dark .ant-card h5,
.layout-container.dark .ant-card h6,
.layout-container.dark .ant-card ul,
.layout-container.dark .ant-card ol,
.layout-container.dark .ant-card li,
.layout-container.dark .ant-collapse-panel p,
.layout-container.dark .ant-collapse-panel h1,
.layout-container.dark .ant-collapse-panel h2,
.layout-container.dark .ant-collapse-panel h3,
.layout-container.dark .ant-collapse-panel h4,
.layout-container.dark .ant-collapse-panel h5,
.layout-container.dark .ant-collapse-panel h6,
.layout-container.dark .ant-collapse-panel ul,
.layout-container.dark .ant-collapse-panel ol,
.layout-container.dark .ant-collapse-panel li,
.layout-container.dark .ant-tabs-tabpane p,
.layout-container.dark .ant-tabs-tabpane h1,
.layout-container.dark .ant-tabs-tabpane h2,
.layout-container.dark .ant-tabs-tabpane h3,
.layout-container.dark .ant-tabs-tabpane h4,
.layout-container.dark .ant-tabs-tabpane h5,
.layout-container.dark .ant-tabs-tabpane h6,
.layout-container.dark .ant-tabs-tabpane ul,
.layout-container.dark .ant-tabs-tabpane ol,
.layout-container.dark .ant-tabs-tabpane li {
    color: #e0e0e0; /* Off-white color for text */
}

/* Ensure smooth transitions for Card, Panel, and Tab components */
.ant-card,
.ant-collapse-panel,
.ant-tabs-tabpane {
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Example: Translate Button Styles */
.layout-container.light #translate-button {
    background-color: #007bff;
}

.layout-container.dark #translate-button {
    background-color: #555555;
}

/* Styles for the hidden Google Translate element */
.layout-container.light #google_translate_element {
    background-color: #ffffff;
    color: #000000;
}

.layout-container.dark #google_translate_element {
    background-color: #333333;
    color: #e0e0e0;
}

/* Ensure that transitions are smooth for external elements */
#translate-button, #google_translate_element {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.layout-container.dark .ant-modal-confirm {
    background-color: #1f1f1f; /* Dark background for modal */
    color: #ffffff; /* Light text for dark theme */
}