/* PhoneInputOverrides.css */

/* Ensure that these styles are loaded after the default react-phone-input-2 styles */

/* Light Theme Styles */
.layout-container.light .react-tel-input .form-control {
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #d9d9d9 !important;
}

.layout-container.light .react-tel-input .flag-dropdown {
    background-color: #ffffff !important;
    border: 1px solid #d9d9d9 !important;
}

.layout-container.light .react-tel-input .country-list .country {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.layout-container.light .react-tel-input .country-list .country:hover {
    background-color: #f0f0f0 !important;
}

/* Dark Theme Styles */
.layout-container.dark .react-tel-input .form-control {
    background-color: #333333 !important;
    color: #e0e0e0 !important;
    border: 1px solid #444444 !important;
}

.layout-container.dark .react-tel-input .flag-dropdown {
    background-color: #333333 !important;
    border: 1px solid #444444 !important;
}

.layout-container.dark .react-tel-input .country-list {
    background-color: #333333 !important;
}

.layout-container.dark .react-tel-input .country-list .country {
    background-color: #333333 !important;
    color: #e0e0e0 !important;
}

.layout-container.dark .react-tel-input .country-list .country:hover {
    background-color: #444444 !important;
}

/* Common Styles for Both Themes */
.react-tel-input {
    width: 100% !important;
}

.react-tel-input .form-control {
    border-radius: 4px !important;
    /* padding: 8px 12px !important; */
    font-size: 14px !important;
}

.react-tel-input .flag-dropdown {
    border-radius: 4px 0 0 4px !important;
}
