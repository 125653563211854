/* Dark mode styles for the modal */
.dark-modal .ant-modal-content {
  background-color: #1f1f1f;
  color: #ffffff;
}

.dark-modal .ant-modal-header {
  background-color: #1f1f1f;
  border-bottom: 1px solid #434343;
}

.dark-modal .ant-modal-title, 
.dark-modal .ant-modal-confirm-title,
.dark-modal .ant-modal-confirm-content {
  color: #ffffff;
}

.dark-modal .ant-modal-body {
  color: #ffffff;
}

.dark-modal .ant-modal-footer {
  background-color: #1f1f1f;
  border-top: 1px solid #434343;
}

/* Light mode styles for the modal (optional, for consistency) */
.light-modal .ant-modal-content {
  background-color: #ffffff;
  color: #000000;
}

.light-modal .ant-modal-header,
.light-modal .ant-modal-footer {
  background-color: #ffffff;
}

.light-modal .ant-modal-title,
.light-modal .ant-modal-body {
  color: #000000;
}
