@media (min-width: 1024px) {
    .recording-modal .ant-modal, .recording-modal .ant-modal-content {
        height: 99vh;
        width: 99vw;
        margin: 0;
        top: 0;
    }
    .recording-modal .ant-modal-body {
        height: calc(100vh - 110px);
        overflow-y: auto;
    }
}

@media (max-width: 1023px) {
    .recording-modal .ant-modal, .recording-modal .ant-modal-content {
        /* height: 100vh; */
        /* width: 100vw; */
        margin: 0;
        top: 0;
        overflow-x: hidden;
    }
    .recording-modal .ant-modal-body {
        /* height: calc(100vh - 110px); */
        /* overflow-y: scroll; */
        overflow-x: hidden;
    }
}