.chat-container {
    display: flex;
    flex-direction: column;
    height: 57vh;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .chat-list {
    overflow: auto;
    flex-grow: 1;
    padding: 10px;
    /* background-color: #e5ddd5; */
  }
  
  .chat-message {
    display: flex;
    justify-content: flex-start; /* Default alignment to left */
    margin-bottom: 10px;
  }
  
  .chat-message.you {
    justify-content: flex-end;
    background-color: #c2deff;
    width: 90%; /* Set the width of bot messages to 80% */
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  
  .chat-message .ant-list-item-meta {
    max-width: 90%;
  }
  
  .chat-message.you .ant-list-item-meta-content {
    text-align: right; /* Align your text to the right */
  }

  .chat-message.bot {
    width: 90%;
    background-color: #fff2c6;
    display: flex;
    align-items: center;
  }

  .avatar-left {
    margin-right: 8px; /* Space between the avatar and the message for bot */
  }
  
  .avatar-right {
    margin-left: 8px; /* Space between the message and the avatar for you */
  }

  .chat-message .ant-list-item-meta-title{
    color: #000 !important;
  }

  .chat-message .message-content {
    font-weight: 600 !important;
    color: #363636;
  }

  .chat-message.you .ant-list-item-meta-description {
    font-weight: 600 !important;
    color: #363636 !important;
  }